import DeleteModal from "components/Common/DeleteModal"
import PropTypes from "prop-types"
import React from "react"
import { MetaTags } from "react-meta-tags"
import { Link } from "react-router-dom"
import { Card, Col, Row } from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import {
  CardBody,
  Container,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap"
const { SearchBar } = Search
const sizePerPage = 10
const pageOptions = {
  sizePerPage: sizePerPage,
  totalSize: 15, // shops.length, // replace later with size(shops),
  custom: true,
}
const CardShop = props => {
  const { shop } = props
  const name = shop.en_name
  const nameIcon = name.charAt(0)
  const keyField = "id"
  const contactListColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,

      // eslint-disable-next-line react/display-name
      formatter: (cellContent, shop) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {shop.id}
            </Link>
          </h5>
          {/* <p className="text-muted mb-0">{shop.designation}</p> */}
        </>
      ),
    },
    {
      text: "Name",
      dataField: "name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, shop) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {shop.shop_name}
            </Link>
          </h5>
          <p className="text-muted mb-0">{shop.designation}</p>
        </>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      text: "Phone",
      dataField: "Phone",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, shop) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {shop.phone}
            </Link>
          </h5>
        </>
      ),
    },
    {
      text: "created at",
      dataField: "created at",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, shop) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {shop.created_at}
            </Link>
          </h5>
        </>
      ),
    },
    // {
    //   text: "Tags",
    //   dataField: "tags",
    //   sort: true,
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, shop) => (
    //     <>
    //       {map(
    //         shop.tags,
    //         (tag, index) =>
    //           index < 2 && (
    //             <Link
    //               to="#"
    //               className="badge badge-soft-primary font-size-11 m-1"
    //               key={"_skill_" + shop.id + index}
    //             >
    //               {tag}
    //             </Link>
    //           )
    //       )}
    //       {size(shop.tags) > 2 && (
    //         <Link
    //           to="#"
    //           className="badge badge-soft-primary font-size-11 m-1"
    //           key={"_skill_" + shop.id}
    //         >
    //           {size(shop.tags) - 1} + more
    //         </Link>
    //       )}
    //     </>
    //   ),
    // },
    // {
    //   dataField: "projects",
    //   text: "Projects",
    //   sort: true,
    // },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, shop) => (
        <div className="d-flex gap-3">
          {/* <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => handleshopClick(shop)}
            ></i>
          </Link> */}
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => onClickDelete(shop, shop.id)}
            ></i>
          </Link>
        </div>
      ),
    },
  ]
  return (
    <React.Fragment>
      <Col xl="4" sm="6">
        <Card>
          <Row>
            <Col xl="5">
              <div className="text-center p-4 border-end">
                <div className="avatar-sm mx-auto mb-3 mt-1">
                  <span
                    className={
                      "avatar-title rounded-circle bg-soft bg-" +
                      shop.color +
                      " primary text-" +
                      shop.color +
                      " font-size-16"
                    }
                  >
                    {nameIcon}
                  </span>
                </div>
                <h5 className="text-truncate">{shop.name}</h5>
              </div>
            </Col>

            <Col xl="7">
              <div className="p-4 text-center text-xl-start">
                <Row>
                  <Col xs="6">
                    <div>
                      <p className="text-muted mb-2 text-truncate">Products</p>
                      <h5>{shop.product}</h5>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div>
                      <p className="text-muted mb-2 text-truncate">
                        Wallet Balance
                      </p>
                      <h5>${shop.balance}</h5>
                    </div>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link to="#" className="text-decoration-underline text-reset">
                    See Profile <i className="mdi mdi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </React.Fragment>
    // <React.Fragment>
    //   {/* <DeleteModal
    //     // show={deleteModal}
    //     onDeleteClick={handleDeleteshop}
    //     onCloseClick={() => setDeleteModal(false)}
    //   /> */}
    //   <div className="page-content">
    //     <MetaTags>
    //       <title>shop List | Skote - React Admin & Dashboard Template</title>
    //     </MetaTags>
    //     <Container fluid>
    //       {/* Render Breadcrumbs */}
    //       <Breadcrumbs title="Contacts" breadcrumbItem="shop List" />
    //       <Row>
    //         <Col lg="12">
    //           <Card>
    //             <CardBody>
    //               <PaginationProvider
    //                 pagination={paginationFactory(pageOptions)}
    //                 keyField={keyField}
    //                 columns={contactListColumns}
    //                 data={shop}
    //               >
    //                 {({ paginationProps, paginationTableProps }) => {
    //                   return (
    //                     <ToolkitProvider
    //                       keyField={keyField}
    //                       data={shop}
    //                       columns={contactListColumns}
    //                       bootstrap4
    //                       search
    //                     >
    //                       {toolkitProps => (
    //                         <React.Fragment>
    //                           <Row className="mb-2">
    //                             <Col sm="4">
    //                               <div className="search-box ms-2 mb-2 d-inline-block">
    //                                 <div className="position-relative">
    //                                   <SearchBar
    //                                     {...toolkitProps.searchProps}
    //                                   />
    //                                   <i className="bx bx-search-alt search-icon" />
    //                                 </div>
    //                               </div>
    //                             </Col>
    //                             <Col sm="8">
    //                               <div className="text-sm-end">
    //                                 <Button
    //                                   color="primary"
    //                                   className="font-16 btn-block btn btn-primary"
    //                                   onClick={handleshopClicks}
    //                                 >
    //                                   <i className="mdi mdi-plus-circle-outline me-1" />
    //                                   Create New shop
    //                                 </Button>
    //                               </div>
    //                             </Col>
    //                           </Row>
    //                           <Row>
    //                             <Col xl="12">
    //                               <div className="table-responsive">
    //                                 <BootstrapTable
    //                                   keyField={keyField}
    //                                   {...toolkitProps.baseProps}
    //                                   {...paginationTableProps}
    //                                   // selectRow={selectRow}
    //                                   defaultSorted={defaultSorted}
    //                                   classes={
    //                                     "table align-middle table-nowrap table-hover"
    //                                   }
    //                                   bordered={false}
    //                                   striped={false}
    //                                   responsive
    //                                   ref={node}
    //                                 />

    //                                 <Modal isOpen={modal} toggle={toggle}>
    //                                   <ModalHeader toggle={toggle} tag="h4">
    //                                     {!!isEdit ? "Edit shop" : "Add shop"}
    //                                   </ModalHeader>
    //                                   <ModalBody>
    //                                     <Form
    //                                       onSubmit={e => {
    //                                         e.preventDefault()
    //                                         validation.handleSubmit()
    //                                         return false
    //                                       }}
    //                                     >
    //                                       <Row form>
    //                                         <Col xs={12}>
    //                                           <div className="mb-3">
    //                                             <Label className="form-label">
    //                                               Name
    //                                             </Label>
    //                                             <Input
    //                                               name="name"
    //                                               type="text"
    //                                               onChange={
    //                                                 validation.handleChange
    //                                               }
    //                                               onBlur={validation.handleBlur}
    //                                               value={
    //                                                 validation.values.name || ""
    //                                               }
    //                                               invalid={
    //                                                 validation.touched.name &&
    //                                                 validation.errors.name
    //                                                   ? true
    //                                                   : false
    //                                               }
    //                                             />
    //                                             {validation.touched.name &&
    //                                             validation.errors.name ? (
    //                                               <FormFeedback type="invalid">
    //                                                 {validation.errors.name}
    //                                               </FormFeedback>
    //                                             ) : null}
    //                                           </div>
    //                                           <div className="mb-3">
    //                                             <Label className="form-label">
    //                                               Designation
    //                                             </Label>
    //                                             <Input
    //                                               name="designation"
    //                                               label="Designation"
    //                                               type="text"
    //                                               onChange={
    //                                                 validation.handleChange
    //                                               }
    //                                               onBlur={validation.handleBlur}
    //                                               value={
    //                                                 validation.values
    //                                                   .designation || ""
    //                                               }
    //                                               invalid={
    //                                                 validation.touched
    //                                                   .designation &&
    //                                                 validation.errors
    //                                                   .designation
    //                                                   ? true
    //                                                   : false
    //                                               }
    //                                             />
    //                                             {validation.touched
    //                                               .designation &&
    //                                             validation.errors
    //                                               .designation ? (
    //                                               <FormFeedback type="invalid">
    //                                                 {
    //                                                   validation.errors
    //                                                     .designation
    //                                                 }
    //                                               </FormFeedback>
    //                                             ) : null}
    //                                           </div>
    //                                           <div className="mb-3">
    //                                             <Label className="form-label">
    //                                               Email
    //                                             </Label>
    //                                             <Input
    //                                               name="email"
    //                                               label="Email"
    //                                               type="email"
    //                                               onChange={
    //                                                 validation.handleChange
    //                                               }
    //                                               onBlur={validation.handleBlur}
    //                                               value={
    //                                                 validation.values.email ||
    //                                                 ""
    //                                               }
    //                                               invalid={
    //                                                 validation.touched.email &&
    //                                                 validation.errors.email
    //                                                   ? true
    //                                                   : false
    //                                               }
    //                                             />
    //                                             {validation.touched.email &&
    //                                             validation.errors.email ? (
    //                                               <FormFeedback type="invalid">
    //                                                 {validation.errors.email}
    //                                               </FormFeedback>
    //                                             ) : null}
    //                                           </div>
    //                                           <div className="mb-3">
    //                                             <Label className="form-label">
    //                                               Option
    //                                             </Label>
    //                                             <Input
    //                                               type="select"
    //                                               name="tags"
    //                                               className="form-select"
    //                                               multiple={true}
    //                                               onChange={
    //                                                 validation.handleChange
    //                                               }
    //                                               onBlur={validation.handleBlur}
    //                                               value={
    //                                                 validation.values.tags || []
    //                                               }
    //                                               invalid={
    //                                                 validation.touched.tags &&
    //                                                 validation.errors.tags
    //                                                   ? true
    //                                                   : false
    //                                               }
    //                                             >
    //                                               <option>Photoshop</option>
    //                                               <option>illustrator</option>
    //                                               <option>Html</option>
    //                                               <option>Php</option>
    //                                               <option>Java</option>
    //                                               <option>Python</option>
    //                                               <option>
    //                                                 UI/UX Designer
    //                                               </option>
    //                                               <option>Ruby</option>
    //                                               <option>Css</option>
    //                                             </Input>
    //                                             {validation.touched.tags &&
    //                                             validation.errors.tags ? (
    //                                               <FormFeedback type="invalid">
    //                                                 {validation.errors.tags}
    //                                               </FormFeedback>
    //                                             ) : null}
    //                                           </div>
    //                                           <div className="mb-3">
    //                                             <Label className="form-label">
    //                                               Projects
    //                                             </Label>
    //                                             <Input
    //                                               name="projects"
    //                                               label="Projects"
    //                                               type="text"
    //                                               onChange={
    //                                                 validation.handleChange
    //                                               }
    //                                               onBlur={validation.handleBlur}
    //                                               value={
    //                                                 validation.values
    //                                                   .projects || ""
    //                                               }
    //                                               invalid={
    //                                                 validation.touched
    //                                                   .projects &&
    //                                                 validation.errors.projects
    //                                                   ? true
    //                                                   : false
    //                                               }
    //                                             />
    //                                             {validation.touched.projects &&
    //                                             validation.errors.projects ? (
    //                                               <FormFeedback type="invalid">
    //                                                 {validation.errors.projects}
    //                                               </FormFeedback>
    //                                             ) : null}
    //                                           </div>
    //                                         </Col>
    //                                       </Row>
    //                                       <Row>
    //                                         <Col>
    //                                           <div className="text-end">
    //                                             <button
    //                                               type="submit"
    //                                               className="btn btn-success save-shop"
    //                                             >
    //                                               Save
    //                                             </button>
    //                                           </div>
    //                                         </Col>
    //                                       </Row>
    //                                     </Form>
    //                                   </ModalBody>
    //                                 </Modal>
    //                               </div>
    //                             </Col>
    //                           </Row>
    //                           <Row className="align-items-md-center mt-30">
    //                             <Col className="pagination pagination-rounded justify-content-end mb-2">
    //                               <PaginationListStandalone
    //                                 {...paginationProps}
    //                               />
    //                             </Col>
    //                           </Row>
    //                         </React.Fragment>
    //                       )}
    //                     </ToolkitProvider>
    //                   )
    //                 }}
    //               </PaginationProvider>
    //             </CardBody>
    //           </Card>
    //         </Col>
    //       </Row>
    //     </Container>
    //   </div>
    // </React.Fragment>
  )
}

CardShop.propTypes = {
  shop: PropTypes.object,
}

export default CardShop
