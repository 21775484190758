import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import * as Yup from "yup"
import { useFormik } from "formik"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

import images from "assets/images"
import {deleteCity, getShops as onGetShops} from "store/e-commerce/actions"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  // deleteUser as onDeleteUser,
} from "store/contacts/actions"
import { getPromos } from "store/actions"
import { getCities } from "store/actions"
import { isEmpty, size, map } from "lodash"
import { deleteShopSuccess } from "store/actions"
//redux
import { useSelector, useDispatch } from "react-redux"
// actions
// import { getDemoData } from "./../../../store/demo/actions"
import { deletePromo } from "store/e-commerce/actions"
import axios from "axios"

const apiLink = "https://beneshty.oncreation.net/api"

const ContactsList = props => {
  const [data, setData] = useState(null)
  const dispatch = useDispatch()
  const [contact, setContact] = useState()
  const [edit, setEdit] = useState(null)
  const [add, setAdd] = useState(null)
  const [editId, setId] = useState()
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      city: (contact && contact.city) || "",
      shipping: (contact && contact.shipping) || "",

    },
    validationSchema: Yup.object({
      city: Yup.string().required("Please Enter english name"),
      shipping: Yup.number().required("Please Enter english address"),

    }),
    onSubmit: values => {
      console.log(values)
      // alert(values)

      if (edit && !add) {
        // edit

        axios
          .post(`${apiLink}/dashboard/city/update/${editId}`, values)
          .then(response => {
            setData(response.data)
          })
      } else {
        // add
        axios.post(`${apiLink}/dashboard/city/store`, values).then(response => {
          setData(response.data)
        })
      }

      toggle()
    },
  })

  // const { users } = useSelector(state => ({
  //   users: state.contacts.users,
  // }))

  // console.log(users)

  const { successMessage } = useSelector(state => ({
    successMessage: state.ecommerce.successMessage,
  }))
  const { promos } = useSelector(state => ({
    promos: state.ecommerce.promos,
  }))

  const { cities } = useSelector(state => ({
    cities: state.ecommerce.cities,
  }))
  // console.log(cities)

  useEffect(() => {
    if (successMessage == null) {
      return
    } else {
      alert(successMessage)
    }
  }, [successMessage])
  const [userList, setUserList] = useState([])

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const { SearchBar } = Search
  const sizePerPage = 10
  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: cities.length, // replace later with size(users),
    custom: true,
  }
  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ]

  const selectRow = {
    mode: "checkbox",
  }

  const contactListColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,

      // eslint-disable-next-line react/display-name
      formatter: (cellContent, city) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {city.id}
            </Link>
          </h5>
          {/* <p className="text-muted mb-0">{user.designation}</p> */}
        </>
      ),
    },
    {
      text: "City",
      dataField: "city",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, city) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {city.city}
            </Link>
          </h5>
        </>
      ),
    },

    {
      text: "Shipping price",
      dataField: "shipping",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, city) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {city.shipping} EGP
            </Link>
          </h5>
        </>
      ),
    },

    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, city) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => handleUserClick(city)}
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => onClickDelete(city, city.id)}
            ></i>
          </Link>
        </div>
      ),
    },
  ]
  useEffect(() => {
    dispatch(getCities())
  }, [dispatch, data])

  useEffect(() => {
    if (cities && !cities.length) {
      dispatch(getCities())
      setIsEdit(false)
    }
  }, [dispatch, cities, data])

  useEffect(() => {
    setContact(cities)
    setIsEdit(false)
  }, [cities])

  useEffect(() => {
    if (!isEmpty(cities) && !!isEdit) {
      setContact(cities)
      setIsEdit(false)
    }
  }, [cities, data])

  const toggle = () => {
    setModal(!modal)
  }

  const handleUserClick = arg => {
    const city = arg
    console.log('city',city)
    setAdd(false)
    setEdit(true)
    setId(city.id)
    setContact({
      id: city.id,
     city: city.city,
      shipping: city.shipping,
    })
    setIsEdit(true)

    toggle()
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = (city, id) => {
    setContact(city)
    setDeleteModal(true)
  }

  const handleDeleteShop = () => {
    console.log('deleeteeeee')
    dispatch(deleteCity(contact.id))
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  const handleUserClicks = () => {
    setEdit(false)
    setAdd(true)
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteShop}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>User List | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="User List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField={keyField}
                    columns={contactListColumns}
                    data={cities}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField={keyField}
                          data={cities}
                          columns={contactListColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                      color="primary"
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={handleUserClicks}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Create New City
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={keyField}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      // selectRow={selectRow}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={node}
                                    />

                                    <Modal isOpen={modal} toggle={toggle}>
                                      <ModalHeader toggle={toggle} tag="h4">
                                        {!!isEdit
                                          ? "Edit City"
                                          : "Add City"}
                                      </ModalHeader>
                                      <ModalBody>
                                        <Form
                                          onSubmit={e => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                          }}
                                        >
                                          <Row form>
                                            <Col xs={12}>
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  City
                                                </Label>
                                                <Input
                                                  name="city"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.city ||
                                                    ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .city &&
                                                    validation.errors.city
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.city &&
                                                validation.errors.city ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.city}
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                 Shipping
                                                </Label>
                                                <Input
                                                  name="shipping"
                                                  label="Designation"
                                                  type="number"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values
                                                      .shipping || ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .shipping &&
                                                    validation.errors.shipping
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched
                                                  .shipping &&
                                                validation.errors.shipping ? (
                                                  <FormFeedback type="invalid">
                                                    {
                                                      validation.errors
                                                        .shipping
                                                    }
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <div className="text-end">
                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-user"
                                                >
                                                  Save
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Form>
                                      </ModalBody>
                                    </Modal>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ContactsList)
