import React, { useState, useEffect, Fragment } from "react"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"

import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"
import * as Yup from "yup"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useFormik, Field } from "formik"
import axios from "axios"
import { values } from "lodash"
// const apiLink = "http://192.168.1.200/api"
const apiLink = "https://beneshty.oncreation.net/api"
let en_name
let ar_name
let en_description
let ar_description
let price
let discount
const EcommerceAddProduct = () => {
  const [typesArray, setTypes] = useState()
  const [categoriesArray, setCategory] = useState()
  const [categoryId, setCategoryId] = useState()
  useEffect(() => {
    axios.get(`${apiLink}/type/view`).then(response => {
      console.log("types response", response)
      setTypes(response.data)
    })
  }, [])
  const [selectedFiles, setselectedFiles] = useState([])

  const options = [
    { value: "AK", label: "Alaska" },
    { value: "HI", label: "Hawaii" },
    { value: "CA", label: "California" },
    { value: "NV", label: "Nevada" },
    { value: "OR", label: "Oregon" },
    { value: "WA", label: "Washington" },
  ]

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  const imagesMap = new Map()
  const [selectedimageFile, setSelectedFile] = useState()
  const [selectedSizeImage, setSelectedSizeImage] = useState()
  const [success, setSuccess] = useState(null)
  const [colorsArray, setColorsArray] = useState()
  const [images, setImages] = useState([{ id: "", image: "" }])
  const [imageColor, setImageColor] = useState()
  const [typeId, setTypeId] = useState()

  // const [images,setImages] = useState(imagesMap)
  let [colorArr, setX] = useState([
    {
      color_code: "#505050",
      sizes: [{ size: "", quantity: null }],
    },
  ])
  let [colors, setColors] = useState([])
  const handleImageChange = e => {
    console.log("myzft swraaa", e.target.files[0])
    setSelectedFile(e.target.files[0])
    // setSelectedFile("image")
  }
  const handleSizeImageChange = e => {
    console.log("swereet elsize", e.target.files[0])
    setSelectedSizeImage(e.target.files[0])

    // setSelectedSizeImage("sizeImage")
  }

  const handleAddColor = () => {
    console.log("addddddddddd")
    console.log(validation.values)
    en_name = validation.values.en_name
    ar_name = validation.values.ar_name
    en_description = validation.values.en_description
    ar_description = validation.values.ar_description
    price = validation.values.price
    discount = validation.values.discount
    setX([
      ...validation.values.colors,
      {
        color_code: "#505050",
        sizes: [{ size: "", quantity: 0 }],
      },
    ])
  }

  const handleColorImage = (e, id) => {
    // setImageColor(e.target.files[0])
    console.log("element", e.target.files[0])
    console.log("id", id)
    handleColorImageToApi(id, e.target.files[0])
    // imagesMap.set(id, e.target.files[0])
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      category_id: categoryId,
      en_name: en_name,
      ar_name: ar_name,
      en_description: en_description,
      ar_description: ar_description,
      price: price,
      size_guide: "",
      discount: 0,
      colors: colorArr,
      serial: "",
    },
    validationSchema: Yup.object({
      en_name: Yup.string().required("Please Enter english name"),
      ar_name: Yup.string().required("Please Enter arabic name"),
      en_description: Yup.string().required("Please Enter english description"),
      ar_description: Yup.string().required("Please Enter arabic description"),
      discount: Yup.number().required("Please Enter discount"),
      price: Yup.number().required("Please Enter price"),
    }),
    onSubmit: values => {
      console.log(values)
      let formData = new FormData()
      formData.append("size_guide", selectedSizeImage)
      formData.append("en_name", values.en_name)
      formData.append("category_id", values.category_id)
      formData.append("ar_name", values.ar_name)
      formData.append("en_description", values.en_description)
      formData.append("ar_description", values.ar_description)
      formData.append("price", values.price)
      formData.append("serial", values.serial)
      formData.append("colors", JSON.stringify(values.colors))
      console.log(formData)
      console.log("valuee send", values)
      console.log("color_array", colorArr)
      axios
        .post(`${apiLink}/dashboard/product/store`, formData)
        .then(response => {
          console.log(response)
          setSuccess(true)
          setColorsArray(response.data.data[0].color)
        })
    },
  })
  const validationImages = useFormik({
    enableReinitialize: true,
    initialValues: {
      images: images,
    },
    onSubmit: values => {
      // console.log(values)
      // console.log(imageColor)
      console.log(imagesMap.get("125"))
      // values.images[0].image = console.log(colorsArray)
    },
  })
  const handleColorImageToApi = (id, colorImage) => {
    console.log(id)
    console.log(colorImage)
    let formData = new FormData()
    formData.append("image", colorImage)
    axios
      .post(`${apiLink}/dashboard/add/image/${id}`, formData)
      .then(response => {
        console.log(response)
      })
  }
  // useEffect(()=>{
  //   axios.get(`${apiLink}/category/view/${1}`).then(response => {
  //     console.log("categories response", response)
  //     setCategory(response.data)
  //     setCategoryId(response.data[0])
  //   })
  // },[])
  const handleDropwDownChange = e => {
    let type = typesArray.find(item => item.en_name == e.target.value)
    console.log("asd response", type)
    axios.get(`${apiLink}/category/view/${type.id}`).then(response => {
      console.log("categories response", response)
      setCategory(response.data)
    })
  }
  const handleDropwDownCatChange = e => {
    let cat = categoriesArray.find(item => item.en_name == e.target.value)
    setCategoryId(cat.id)
  }
  const handleAddSize = i => {
    console.log("gameed")
    en_name = validation.values.en_name
    ar_name = validation.values.ar_name
    en_description = validation.values.en_description
    ar_description = validation.values.ar_description
    price = validation.values.price
    discount = validation.values.discount
    validation.values.colors[i].sizes.push({ size: "", quantity: "" })
    setX([...validation.values.colors])
    // setX([
    //         ...validation.values.colors,
    //   {
    //     sizes: [...validation.values.colors[i].sizes , {size:'',quantity:''}]
    //   }
    //
    // ])
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add Product | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Add Product" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Basic Information</CardTitle>
                  <CardSubtitle className="mb-4">
                    Fill all information below
                  </CardSubtitle>

                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">type</Label>
                          <select
                            as="select"
                            name="color"
                            className="form-control"
                            onChange={e => {
                              handleDropwDownChange(e)
                            }}
                          >
                            {typesArray &&
                              typesArray.map(item => {
                                return (
                                  <option key={item.id} value={item.en_name}>
                                    {item.en_name}
                                  </option>
                                )
                              })}
                          </select>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">Category</Label>
                          <select
                            as="select"
                            name="color"
                            className="form-control"
                            onChange={e => {
                              handleDropwDownCatChange(e)
                            }}
                          >
                            {categoriesArray &&
                              categoriesArray.map(item => {
                                return (
                                  <option key={item.id} value={item.en_name}>
                                    {item.en_name}
                                  </option>
                                )
                              })}
                          </select>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            product name (english)
                          </Label>
                          <Input
                            name="en_name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.en_name}
                          />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            product name (Arabic)
                          </Label>
                          <Input
                            name="ar_name"
                            label="Designation"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.ar_name || ""}
                            invalid={
                              validation.touched.ar_name &&
                              validation.errors.ar_name
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Col>

                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            English description
                          </Label>
                          <textarea
                            name="en_description"
                            label="Designation"
                            type="number"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.en_description || ""}
                          />
                        </div>
                      </Col>

                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Arabic description
                          </Label>
                          <textarea
                            name="ar_description"
                            label="Designation"
                            type="number"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.ar_description || ""}
                          />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">Price</Label>
                          <Input
                            name="price"
                            label="Designation"
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.price || ""}
                            invalid={
                              validation.touched.price &&
                              validation.errors.price
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">Size Guide image</Label>
                          <Input
                            name="icon"
                            label="Designation"
                            type="file"
                            onChange={handleSizeImageChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.size_guide || ""}
                            invalid={
                              validation.touched.size_guide &&
                              validation.errors.size_guide
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Col>

                      <Col sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">Serial Number</Label>
                          <Input
                            name="serial"
                            label="Designation"
                            type="text"
                            min="1"
                            max="100"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.serial}
                            invalid={
                              validation.touched.serial &&
                              validation.errors.serial
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Col>

                      {validation.values.colors.map((item, i, array) => {
                        // console.log("key", array[i].Object.keys)
                        return (
                          <React.Fragment key={item.id}>
                            <Col sm={8}>
                              <div className="mb-3">
                                <Label className="form-label">color_code</Label>
                                <Input
                                  name={`colors.${i}.color_code`}
                                  type="color"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    colorArr
                                      ? validation.values.colors[i]
                                          .color_code || ""
                                      : ""
                                  }
                                  // invalid={
                                  //   colorArr
                                  //     ? validation.touched.colors[0].color_code &&
                                  //       validation.errors.colors[0].color_code
                                  //       ? true
                                  //       : false
                                  //     : ""
                                  // }
                                />
                              </div>
                            </Col>
                            <Col sm={4} style={{ marginTop: "1rem" }}>
                              <div className="mb-3 ">
                                <button
                                  type={"button"}
                                  className={"form-control"}
                                  style={{
                                    display: "inline-block",
                                    width: "30%",
                                    marginTop: ".5rem",
                                  }}
                                  onClick={() => {
                                    handleAddSize(i)
                                  }}
                                >
                                  + Add Size
                                </button>
                                {/*<span>Add Size</span>*/}
                              </div>
                            </Col>
                            {validation.values.colors[i].sizes.map(
                              (item, si) => {
                                return (
                                  <React.Fragment key={"size" + si}>
                                    <Col sm={6}>
                                      <div className="mb-3 ">
                                        <Label className="form-label">
                                          Size
                                        </Label>
                                        <Input
                                          name={`colors.${i}.sizes.${si}.size`}
                                          type="text"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            array
                                              ? validation.values.colors[i]
                                                  .sizes[si].size || ""
                                              : ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div className="mb-3">
                                        <Label className="form-label">
                                          Quantity
                                        </Label>
                                        <Input
                                          name={`colors.${i}.sizes.${si}.quantity`}
                                          type="number"
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            colorArr
                                              ? validation.values.colors[i]
                                                  .sizes[si].quantity || ""
                                              : ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </React.Fragment>
                                )
                              }
                            )}
                          </React.Fragment>
                        )
                      })}
                      <Col sm={12}>
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{
                            marginBottom: "10px",
                            display: "block",
                            width: "100%",
                          }}
                          onClick={handleAddColor}
                        >
                          Add Color
                        </button>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-success save-user"
                            style={{ display: "block", width: "100%" }}
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle>Images</CardTitle>
                  {/* <CardSubtitle className="mb-3">
                    Fill all information below
                  </CardSubtitle> */}

                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validationImages.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col sm={6}>
                        {colorsArray
                          ? colorsArray.map((item, i) => {
                              console.log("iteeeem", item)
                              return (
                                <React.Fragment key={item.id}>
                                  <div className="mb-3">
                                    <span
                                      htmlFor="metatitle"
                                      style={{
                                        backgroundColor: item.color_code,
                                        color: "white",
                                      }}
                                    >
                                      {item.color_code}
                                    </span>
                                    <Input
                                      id="metatitle"
                                      name={`images.${i}.image`}
                                      type="file"
                                      className="form-control"
                                      onChange={e => {
                                        handleColorImage(e, item.id)
                                      }}
                                    />
                                  </div>
                                </React.Fragment>
                              )
                            })
                          : ""}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct
