/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { getOrders as onGetOrders } from "store/actions"

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import "./eccomerce.css"
import img7 from "../../../assets/images/product/img-7.png"
import img4 from "../../../assets/images/product/img-4.png"
import axios from "axios"

const choices = [
  { id: 1, status_code: 0, title: "pending" },
  { id: 2, status_code: 1, title: "Processing" },
  {
    id: 3,
    status_code: 2,
    title: "Shipped",
  },
  { id: 4, status_code: 3, title: "Delivered" },
  { id: 5, status_code: 4, title: "rejected" },
]

const EcommerceOrdersModal = props => {
  const { isOpen, toggle, orderDetails } = props
  let dispatch = useDispatch()
  const [statusIndex, setIndex] = useState(0)
  console.log(orderDetails)
  // const [orderDetails, setOrderDetails] = useState()

  useEffect(() => {
    setIndex(orderDetails ? orderDetails.order.status : "")
  }, [toggle])
  const handleClick = (i, status_code, id) => {
    setIndex(status_code)

    axios
      .post(
        `https://beneshty.oncreation.net/api/dashboard/order/update/${id}`,
        { status: status_code }
      )
      .then(response => {

        setIndex(response.data.data.status)
        //location.reload()
      })
    // if(status_code !== null){
    //     axios.get(`${apiLink}/api/order/filter/${status_code}`,{headers}).then((response)=>{
    //         setOrders(response.data)
    //     })
    // }else{
    //     axios.get(`${apiLink}/api/order/view`,{headers}).then((response)=>{
    //         setOrders(response.data)
    //     })
    // }
  }
  const handleSaveClick = () => {
    dispatch(onGetOrders())
    toggle()
  }
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Order Details</ModalHeader>
        <ModalBody>
         <p className="mb-2">
            Order id: <span className="text-primary">#{orderDetails ? orderDetails.order.id : ''}</span>
          </p>
          <p className="mb-2">
            Address: <span className="text-primary">{orderDetails ? orderDetails.address.address : ''}</span>
          </p>
          <p className="mb-2">
            Phone Number: <span className="text-primary">{orderDetails ? orderDetails.order.another_phone : ''}</span>
          </p>
          <div className="table-responsive">

            <Table className="table align-middle table-nowrap">

              <thead>

                <tr>
                  <th scope="col">Product</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Price</th>
                </tr>
              </thead>
              <tbody>
                {orderDetails
                  ? orderDetails.order.order_product.map(item => {
                      return (
                        <tr key={item.id}>
                          <th scope="row">
                            <div>
                              <img
                                src={item.image}
                                alt=""
                                className="avatar-sm"
                              />
                            </div>
                          </th>
                          <td>
                            <div>
                              <h5 className="text-truncate font-size-14">
                                {item.name}
                              </h5>
                              <p className="text-muted mb-0">
                                {item.price} x {item.quantity}
                              </p>
                              <p className="text-muted mb-0">
                               Size : {item.size}
                              </p>
                              <p className="text-muted mb-0">
                                Color : <span style={{backgroundColor : item.color}}>{item.color}</span>
                              </p>
                            </div>
                          </td>
                          <td>{item.total} EGP</td>
                        </tr>
                      )
                    })
                  : ""}

                {/* <tr>
                  <th scope="row">
                    <div>
                      <img src={img4} alt="" className="avatar-sm" />
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">
                        Hoodie (Blue)
                      </h5>
                      <p className="text-muted mb-0">$ 145 x 1</p>
                    </div>
                  </td>
                  <td>$ 145</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-end">Sub Total:</h6>
                  </td>
                  <td>{orderDetails ? orderDetails[0]?.sub_total : ""} EGP</td>
                </tr> */}
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-end">Sub Total:</h6>
                  </td>
                  <td>{orderDetails ? orderDetails.order.sub_total : ""} EGP</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-end">Discount:</h6>
                  </td>
                  <td>
                    {orderDetails
                      ? orderDetails.order.promocode.replace("%", "") *
                          (orderDetails.order.sub_total / 100) +
                        " EGP (" +
                        orderDetails.order.promocode +
                        ")"
                      : ""}{" "}
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-end">Shipping:</h6>
                  </td>
                  <td>{orderDetails ? orderDetails.order.shipping : ""} EGP</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-end">Total:</h6>
                  </td>
                  <td>
                    {orderDetails ? orderDetails.order.total_price : ""} EGP
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="container mb-5">
            <div className="row">
              <div className="col-lg-12 d-flex justify-content-between mb-3 choices">
                {choices.map((item, i) => {
                  const orderStatus = orderDetails
                    ? orderDetails.order.status
                    : ""
                  const orderId = orderDetails ? orderDetails.order.id : ""
                  const activate = statusIndex === i ? "active" : ""
                  console.log(statusIndex)
                  return (
                    <a
                      className={activate}
                      href="#"
                      key={item.id}
                      onClick={() => {
                        handleClick(i, item.status_code, orderId), toggle
                      }}
                    >
                      {item.title}
                    </a>
                  )
                })}
              </div>

              {/* <Button
                type="button"
                className="mb-2"
                color="secondary"
                onClick={toggle}
              >
                Close
              </Button> */}
              <Button type="button" color="success" onClick={handleSaveClick}>
                save
              </Button>
            </div>
          </div>
        </ModalFooter>
      </div>
    </Modal>
  )
}

EcommerceOrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default EcommerceOrdersModal
