import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { logoutUser } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import axios from "axios";

const Logout = props => {
  const dispatch = useDispatch()

  useEffect(() => {
      localStorage.removeItem('authUser')
    localStorage.removeItem('dashboard-token')
      window.location.href = '/login'
  }, [])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Logout)
