  import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { useFormik, Field } from "formik"
import deleteButtton from "../../../assets/images/Icon.png"
import editButton from "../../../assets/images/edit-icon.png"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
} from "reactstrap"
import classnames from "classnames"
import { isEmpty } from "lodash"

//Import Star Ratings
import StarRatings from "react-star-ratings"

//Import Product Images
import { productImages } from "assets/images/product"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import actions
import {
  getProductDetail as onGetProductDetail,
  getProductComments,
  onAddReply as onAddReplyAction,
  onAddComment as onAddCommentAction,
} from "store/actions"
import RecentProducts from "./RecentProducts"
import Reviews from "./Reviews"

const apiLink = "https://beneshty.oncreation.net/api"
const handleProductDelete = id => {
  axios.post(`${apiLink}/dashboard/product/delete/${id}`).then(response => {
    console.log(response)
  })
}
//redux
import { useSelector, useDispatch } from "react-redux"
import axios from "axios"

const EcommerceProductDetail = props => {
  const [isHovering, setIsHovering] = useState(false)
  const [colorSizes, setColorSizes] = useState()
  const [colorId, setColorId] = useState()
  const [imageData, setImageData] = useState()
  const [SizeModal, setSizeModal] = useState(false)
  const [ColorModal, setColorModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [ColorAddModal, setColorAddModal] = useState(false)
  const [colorAddSuccessful, setColorAddSuccessful] = useState(null)
  const [addedColorId, setaddedColorId] = useState()
  const dispatch = useDispatch()

  const { product, productComments } = useSelector(state => ({
    product: state.ecommerce.product,
    productComments: state.ecommerce.productComments,
  }))

  const editModalToggle = () => {
    setEditModal(!editModal)
    console.log("gameeeed")
  }

  console.log(product)
  const {
    match: { params },
  } = props
  //const [activeTab, setActiveTab] = useState(product?.color[0]?.image[0]?.image)
  const handleDeleteColor = id => {
    console.log(id)
    axios.post(`${apiLink}/dashboard/color/delete/${id}`).then(response => {
      console.log(response)
    })
  }
  const handleColorSize = id => {
    const colorItem = product.color.find(item => {
      return item.id === id
    })
    console.log("color-item", colorItem.size)
    setColorSizes(colorItem.size)
    setColorId(id)
    setImageData({
      id: colorItem?.image[0]?.id,
      url: colorItem?.image[0]?.image,
    })
  }
  const handleDeleteSize = id => {
    console.log(id)
    axios.post(`${apiLink}/dashboard/size/delete/${id}`).then(response => {
      console.log(response)
      setColorSizes(null)
    })
  }
  const handleEditColor = id => {
    console.log(id)
    // axios.post(`${apiLink}/dashboard/color/delete/${id}`).then(response => {
    //   console.log(response)
    // })
    colorToggle()
  }
  useEffect(() => {
    if (params && params.id) {
      dispatch(onGetProductDetail(params.id))
    } else {
      dispatch(onGetProductDetail(1))
    }
  }, [dispatch, params])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      size: "",
      quantity: "",
    },
    onSubmit: values => {
      console.log(values)
      axios
        .post(`${apiLink}/dashboard/add/size/${colorId}`, values)
        .then(response => {
          console.log(response)
          sizeToggle()
        })
    },
  })
  const validationColor = useFormik({
    enableReinitialize: true,
    initialValues: {
      color_code: "",
    },
    onSubmit: values => {
      console.log(values)
      axios
        .post(`${apiLink}/dashboard/update/color/${colorId}`, values)
        .then(response => {
          console.log(response)
          colorToggle()
        })
    },
  })
  // const toggleTab = tab => {
  //   if (activeTab !== tab) {
  //     setActiveTab(tab)
  //   }
  // }
  const validationAddColor = useFormik({
    enableReinitialize: true,
    initialValues: {
      colors: [
        {
          color_code: "",
          sizes: [{ size: "", quantity: "" }],
        },
      ],
    },
    onSubmit: values => {
      console.log(values)
      axios
        .post(`${apiLink}/dashboard/add/color/${product.id}`, values)
        .then(response => {
          let colorArray = response?.data?.data[0]?.color
          console.log("my response", response, "len", colorArray.length)
          setaddedColorId(colorArray[colorArray.length - 1].id ?? -1)
          console.log(colorArray[colorArray.length - 1].id ?? -1)
          setColorAddSuccessful(!colorAddSuccessful)
        })
    },
  })

  const handleImageChange = e => {
    console.log(e.target.files[0])
    let formData = new FormData()
    formData.append("image", e.target.files[0])
    axios
      .post(`${apiLink}/dashboard/add/image/${addedColorId}`, formData)
      .then(response => {
        console.log(response)
        setColorAddSuccessful(!colorAddSuccessful)
        colorAddToggle()
      })
  }

  const imageShow = (img, id) => {
    const expandImg = document.getElementById("expandedImg")
    console.log("imageShow image", img)
    expandImg.src = img
  }

  // useEffect(() => {
  //   dispatch(getProductComments())
  // }, [dispatch])
  const sizeToggle = () => {
    setSizeModal(!SizeModal)
  }
  const colorToggle = () => {
    setColorModal(!ColorModal)
  }
  const colorAddToggle = () => {
    setColorAddModal(!ColorAddModal)
  }
  const [comments, setComments] = useState([])
  useEffect(() => {
    if (productComments) {
      setComments(productComments)
    }
  }, [productComments])

  const onClickReply = commentId => {
    const modifiedComments = [...comments]

    const commentIdx = (modifiedComments || []).findIndex(
      comment => comment.commentId.toString() === commentId.toString()
    )
    if (commentIdx > -1) {
      if (modifiedComments[commentIdx]) {
        modifiedComments[commentIdx]["showAddComment"] = true

        for (let i = 0; i < (modifiedComments || []).length; i++) {
          if (i !== commentIdx) {
            modifiedComments[i]["showAddComment"] = false
          }
        }
      } else {
        modifiedComments[commentIdx]["showAddComment"] = false
      }
      setComments(modifiedComments)
    }
  }

  const onCancelReply = commentId => {
    if (commentId) {
      const modifiedComments = [...comments]
      for (let i = 0; i < (modifiedComments || []).length; i++) {
        modifiedComments[i]["showAddComment"] = false
      }
      setComments(modifiedComments)
    }
  }

  const validtionProductEdit = useFormik({
    enableReinitialize: true,
    initialValues: {
      en_name: product.en_name,
      ar_name: product.ar_name,
      en_description: product.en_description,
      ar_description: product.ar_description,
      price: product.price,
      discount: product.discount ?? 0,
    },
    onSubmit: values => {
      console.log(values)
      axios
        .post(`${apiLink}/dashboard/update/product/${product.id}`, values)
        .then(response => {
          console.log(response)
        })
    },
  })
  const handleEditImage = e => {
    let formData = new FormData()
    formData.append("image", e.target.files[0])
    axios
      .post(`${apiLink}/dashboard/update/image/${imageData.id}`, formData)
      .then(response => {
        console.log(response)
      })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Product Detail | Skote - React Admin & Dashboard Template
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Product Detail" />
          {!isEmpty(product) && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl="6">
                        <div className="product-detai-imgs">
                          <Row>
                            <Col md="2" xs="3">
                              <Nav className="flex-column" pills>
                                <NavItem>
                                  {product.color.map(item => {
                                    return (
                                      <React.Fragment key={item.id}>
                                        <NavLink
                                          className={classnames({
                                            active: true,
                                          })}
                                          onClick={() => {
                                            //toggleTab(item.image[0].image)
                                            imageShow(item.image[0].image, 0)
                                          }}
                                        >
                                          <img
                                            src={item?.image[0]?.image ?? ""}
                                            alt=""
                                            onClick={() => {
                                              // imageShow(item.image[0].image, 0)
                                            }}
                                            className="img-fluid mx-auto d-block rounded"
                                          />
                                        </NavLink>
                                      </React.Fragment>
                                    )
                                  })}
                                </NavItem>
                              </Nav>
                            </Col>
                            <Col md={{ size: 7, offset: 1 }} xs="9">
                              <img
                                src={product?.color[0]?.image[0]?.image}
                                alt=""
                                id="expandedImg"
                                className="img-fluid mx-auto d-block"
                              />
                              <TabContent>
                                <TabPane tabId="1">
                                  <div></div>
                                </TabPane>
                                <TabPane tabId="2">
                                  <div>
                                    {/* <img
                                      src={productImages[product.image]}
                                      id="expandedImg2"
                                      alt=""
                                      className="img-fluid mx-auto d-block"
                                    /> */}
                                  </div>
                                </TabPane>
                                <TabPane tabId="3">
                                  <div>
                                    {/* <img
                                      src={productImages[product.image]}
                                      id="expandedImg3"
                                      alt=""
                                      className="img-fluid mx-auto d-block"
                                    /> */}
                                  </div>
                                </TabPane>
                                <TabPane tabId="4">
                                  <div>
                                    {/* <img
                                      src={productImages[product.image]}
                                      id="expandedImg4"
                                      alt=""
                                      className="img-fluid mx-auto d-block"
                                    /> */}
                                  </div>
                                </TabPane>
                              </TabContent>
                              {/* <div className="text-center">
                                <Button
                                  type="button"
                                  color="primary"
                                  className="btn  mt-2 me-1"
                                >
                                  <i className="bx bx-cart me-2" /> Add to cart
                                </Button>
                                <Button
                                  type="button"
                                  color="success"
                                  className="ms-1 btn mt-2"
                                >
                                  <i className="bx bx-shopping-bag me-2" />
                                  Buy now
                                </Button>
                              </div> */}
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Col xl="6">
                        <div className="mt-4 mt-xl-3">
                          <Link to="#" className="text-primary">
                            {product.category}
                          </Link>
                          <h4 className="mt-1 mb-3">{product.name}</h4>

                          <div className="text-muted float-start me-3">
                            <StarRatings
                              rating={product?.rate ?? 0} //{product.rate === null ? 0 : product.rate}
                              starRatedColor="#F1B44C"
                              starEmptyColor="#2D363F"
                              numberOfStars={5}
                              name="rating"
                              starDimension="14px"
                              starSpacing="3px"
                            />
                          </div>
                          <p className="text-muted mb-4">
                            ( {product.reviews} Customers Review )
                          </p>

                          {!!product.isOffer && (
                            <h6 className="text-success text-uppercase">
                              {product.offer} % Off
                            </h6>
                          )}
                          <h5 className="mb-4">
                            Price : <b>{product.price} EGP</b>
                          </h5>
                          <p className="text-muted mb-4">
                            {product.en_description}
                          </p>
                          <Row className="mb-3">
                            <Col md="6">
                              {product.features &&
                                product.features.map((item, i) => (
                                  <div key={i}>
                                    <p className="text-muted">
                                      <i
                                        className={classnames(
                                          item.icon,
                                          "font-size-16 align-middle text-primary me-2"
                                        )}
                                      />
                                      {item.type && `${item.type}: `}
                                      {item.value}
                                    </p>
                                  </div>
                                ))}
                            </Col>
                            <Col md="6">
                              {product.features &&
                                product.features.map((item, i) => (
                                  <div key={i}>
                                    <p className="text-muted">
                                      <i
                                        className={classnames(
                                          item.icon,
                                          "font-size-16 align-middle text-primary me-2"
                                        )}
                                      />
                                      {item.type && `${item.type}:`}
                                      {item.value}
                                    </p>
                                  </div>
                                ))}
                            </Col>
                          </Row>

                          <div className="product-color">
                            <h5 className="font-size-15">Color :</h5>
                            {product.color.map((item, i) => (
                              <React.Fragment key={item.id}>
                                <div
                                  style={{
                                    backgroundColor: item.color_code,
                                    marginRight: "1rem",
                                    padding: ".5rem",
                                    color: "white",
                                    display: "inline-block",
                                  }}
                                  onClick={() => {
                                    handleColorSize(item.id)
                                  }}
                                >
                                  {item.color_code}
                                  <button
                                    className="btn btn-primary"
                                    style={{
                                      backgroundColor: "white",
                                      marginLeft: "5px",
                                      padding: "5px",
                                    }}
                                    onClick={() => {
                                      handleDeleteColor(item.id)
                                    }}
                                  >
                                    <img
                                      src={deleteButtton}
                                      alt="delete"
                                      style={{}}
                                    />
                                  </button>
                                  <button
                                    className="btn btn-primary"
                                    style={{
                                      backgroundColor: "white",
                                      marginLeft: "5px",
                                      padding: "5px",
                                    }}
                                    onClick={() => {
                                      handleEditColor(item.id)
                                    }}
                                  >
                                    <img
                                      src={editButton}
                                      alt="edit"
                                      style={{}}
                                    />
                                  </button>
                                </div>
                              </React.Fragment>
                            ))}
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              onClick={colorAddToggle}
                            >
                              +
                            </button>
                            <Modal isOpen={ColorModal} toggle={colorToggle}>
                              <ModalHeader
                                toggle={colorToggle}
                                tag="h4"
                              ></ModalHeader>
                              <ModalBody>
                                <Form
                                  onSubmit={e => {
                                    e.preventDefault()
                                    validationColor.handleSubmit()
                                    return false
                                  }}
                                >
                                  <Input
                                    placeholder="size"
                                    className="form-control"
                                    name="color_code"
                                    type="color"
                                    onChange={validationColor.handleChange}
                                    onBlur={validationColor.handleBlur}
                                    value={
                                      validationColor.values.color_code || ""
                                    }
                                  />
                                  <br />
                                  <button
                                    className="btn btn-success"
                                    style={{ width: "100%", display: "block" }}
                                    type="submit"
                                  >
                                    Save
                                  </button>
                                </Form>
                              </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={ColorAddModal}
                              toggle={colorAddToggle}
                            >
                              <ModalHeader
                                toggle={colorAddToggle}
                                tag="h4"
                              ></ModalHeader>
                              <ModalBody>
                                <Form
                                  onSubmit={e => {
                                    e.preventDefault()
                                    validationAddColor.handleSubmit()
                                    return false
                                  }}
                                >
                                  <Input
                                    placeholder="size"
                                    className="form-control"
                                    name="colors[0].color_code"
                                    type="color"
                                    onChange={validationAddColor.handleChange}
                                    onBlur={validationAddColor.handleBlur}
                                    value={
                                      validationAddColor.values.colors[0]
                                        .color_code || ""
                                    }
                                  />
                                  <br />
                                  <Input
                                    placeholder="size"
                                    className="form-control"
                                    name="colors[0].sizes[0].size"
                                    type="text"
                                    onChange={validationAddColor.handleChange}
                                    onBlur={validationAddColor.handleBlur}
                                    value={
                                      validationAddColor.values.colors[0]
                                        .sizes[0].size || ""
                                    }
                                  />
                                  <br />
                                  <Input
                                    placeholder="quantity"
                                    className="form-control"
                                    name="colors[0].sizes[0].quantity"
                                    type="number"
                                    onChange={validationAddColor.handleChange}
                                    onBlur={validationAddColor.handleBlur}
                                    value={
                                      validationAddColor.values.colors[0]
                                        .sizes[0].quantity || ""
                                    }
                                  />
                                  <br />
                                  <button
                                    className="btn btn-success"
                                    style={{ width: "100%", display: "block" }}
                                    type="submit"
                                  >
                                    Save
                                  </button>
                                </Form>
                                <br />
                                {colorAddSuccessful && (
                                  <form>
                                    <input
                                      type="file"
                                      className="form-control"
                                      onChange={handleImageChange}
                                    />
                                  </form>
                                )}
                              </ModalBody>
                            </Modal>
                          </div>
                          <div className="product-color">
                            <h5 className="font-size-15">Size :</h5>
                            {colorSizes
                              ? colorSizes.map((item, i) => (
                                  <div
                                    key={item.id}
                                    style={{
                                      border: "1px solid black",
                                      display: "inline-block",
                                      padding: "0.5rem",
                                      marginRight: ".5rem",
                                    }}
                                  >
                                    <span>Size: {`\"${item.size}\"`}</span>
                                    <span style={{ marginLeft: "10px" }}>
                                      Quantity: {`\"${item.quantity}\"`}
                                    </span>
                                    <button
                                      className="btn btn-primary"
                                      style={{
                                        backgroundColor: "white",
                                        marginLeft: "5px",
                                        padding: "5px",
                                      }}
                                      onClick={() => {
                                        handleDeleteSize(item.id)
                                      }}
                                    >
                                      <img
                                        src={deleteButtton}
                                        alt="delete"
                                        style={{}}
                                      />
                                    </button>
                                  </div>
                                ))
                              : ""}
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              onClick={sizeToggle}
                            >
                              +
                            </button>
                            {colorSizes ? (
                              <form>
                                <Row>
                                  <img
                                    src={imageData?.url ?? ""}
                                    alt=""
                                    style={{ width: "135px" }}
                                    onClick={() => {
                                      // imageShow(item.image[0].image, 0)
                                    }}
                                    className="img-fluid mx-auto d-block rounded"
                                  />
                                  <input
                                    type="file"
                                    className="form-control"
                                    onChange={handleEditImage}
                                  />
                                </Row>
                              </form>
                            ) : (
                              ""
                            )}
                            <Modal isOpen={SizeModal} toggle={sizeToggle}>
                              <ModalHeader
                                toggle={sizeToggle}
                                tag="h4"
                              ></ModalHeader>
                              <ModalBody>
                                <Form
                                  onSubmit={e => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return false
                                  }}
                                >
                                  <Input
                                    placeholder="size"
                                    className="form-control"
                                    name="size"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.size || ""}
                                  />
                                  <br />
                                  <Input
                                    placeholder="quantity"
                                    className="form-control"
                                    name="quantity"
                                    type="number"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.quantity || ""}
                                  />
                                  <br />
                                  <button
                                    className="btn btn-success"
                                    style={{ width: "100%", display: "block" }}
                                    type="submit"
                                  >
                                    Save
                                  </button>
                                </Form>
                              </ModalBody>
                            </Modal>

                            {/* <form style={{ width: "50%" }}>
                              <input
                                className="form-control"
                                placeholder="size"
                              />
                              <input
                                className="form-control"
                                placeholder="quantity"
                              />
                            </form> */}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="mt-5">
                      <div className="table-responsive">
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            handleProductDelete(product.id)
                          }}
                        >
                          Delete
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            editModalToggle()
                          }}
                        >
                          Edit
                        </button>
                      </div>
                    </div>

                    <Modal isOpen={editModal} toggle={editModalToggle}>
                      <ModalHeader
                        toggle={editModalToggle}
                        tag="h4"
                      ></ModalHeader>
                      <ModalBody>
                        <Form
                          onSubmit={e => {
                            e.preventDefault()
                            validtionProductEdit.handleSubmit()
                            return false
                          }}
                        >
                          <label>English name</label>
                          <Input
                            placeholder="english name"
                            className="form-control"
                            name="en-name"
                            type="text"
                            onChange={validtionProductEdit.handleChange}
                            onBlur={validtionProductEdit.handleBlur}
                            value={validtionProductEdit.values.en_name || ""}
                          />
                          <br />
                          <label>Arabic name</label>
                          <Input
                            placeholder="arabic name"
                            className="form-control"
                            name="ar_name"
                            type="text"
                            onChange={validtionProductEdit.handleChange}
                            onBlur={validtionProductEdit.handleBlur}
                            value={validtionProductEdit.values.ar_name || ""}
                          />
                          <br />
                          <label>English description</label>
                          <Input
                            placeholder="english description"
                            className="form-control"
                            name="en_description"
                            type="text"
                            onChange={validtionProductEdit.handleChange}
                            onBlur={validtionProductEdit.handleBlur}
                            value={
                              validtionProductEdit.values.en_description || ""
                            }
                          />
                          <br />
                          <label>Arabic description</label>
                          <Input
                            placeholder="arabic description"
                            className="form-control"
                            name="ar_description"
                            type="text"
                            onChange={validtionProductEdit.handleChange}
                            onBlur={validtionProductEdit.handleBlur}
                            value={
                              validtionProductEdit.values.ar_description || ""
                            }
                          />
                          <br />
                          <label>Price</label>
                          <Input
                            placeholder="price"
                            className="form-control"
                            name="price"
                            type="number"
                            onChange={validtionProductEdit.handleChange}
                            onBlur={validtionProductEdit.handleBlur}
                            value={validtionProductEdit.values.price || 0}
                          />
                          <br />
                          <label>Discount</label>
                          <Input
                            placeholder="discount"
                            className="form-control"
                            name="discount"
                            type="number"
                            onChange={validtionProductEdit.handleChange}
                            onBlur={validtionProductEdit.handleBlur}
                            value={validtionProductEdit.values.discount || 0}
                          />
                          <br />

                          <button
                            className="btn btn-success"
                            style={{ width: "100%", display: "block" }}
                            type="submit"
                          >
                            Save
                          </button>
                        </Form>
                      </ModalBody>
                    </Modal>

                    {/* <Reviews
                      comments={[...comments]}
                      productId={params.id || 1}
                      onClickReply={onClickReply}
                      onCancelReply={onCancelReply}
                      onAddReply={onAddReply}
                      onAddComment={onAddComment}
                    /> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {/* <RecentProducts recentProducts={product.recentProducts} /> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

EcommerceProductDetail.propTypes = {
  product: PropTypes.object,
  match: PropTypes.any,
  onGetProductDetail: PropTypes.func,
}

export default EcommerceProductDetail
