import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect, useHistory } from "react-router-dom"

let isInLogin = false
const Authmiddleware = ({
  component: Component,
  layout: Layout,
  // isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      // isAuthProtected && !localStorage.getItem("authUser")
      // if()
      // return (
      //         <Redirect
      //         to={{ pathname: "/login", state: { from: props.location } }}
      //   />
      // )

        // const user = localStorage.getItem("authUser")
        // console.log("auth", user)
      // if ( user === "admin" ) {
      //   // <Redirect to={{ pathname: "/login", state: { from: props.location } }}/>
      //
      //   // window.stop()
      //   //   <Redirect to={{ pathname: "/login", state: { from: props.location } }}/>
      //     window.location.href = "/dashboard"
      // } else {
      //   // <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }}/>
      //
      //     window.location.href = "/login"
      //     window.stop()
      // }
      //   if (!isInLogin && !user) {
      //       isInLogin = true
      //       window.location.href = "/login"
      //   }


      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
