import React, { useState } from "react"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"

import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
import Dropzone from "react-dropzone"
import * as Yup from "yup"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useFormik, Field } from "formik"
import axios from "axios"
import { values } from "lodash"
// const apiLink = "http://192.168.1.200/api"
const apiLink = "https://beneshty.oncreation.net/api"
const EcommerceTestProduct = () => {
  const [selectedFiles, setselectedFiles] = useState([])

  const options = [
    { value: "AK", label: "Alaska" },
    { value: "HI", label: "Hawaii" },
    { value: "CA", label: "California" },
    { value: "NV", label: "Nevada" },
    { value: "OR", label: "Oregon" },
    { value: "WA", label: "Washington" },
  ]

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  const [selectedimageFile, setSelectedFile] = useState()
  const [selectedSizeImage, setSelectedSizeImage] = useState()
  const [success, setSuccess] = useState(null)
  let [colorArr, setX] = useState([
    {
      color_code: "#505050",
      sizes: [{ size: "XL", quantity: 10 }],
    },
  ])
  let [colors, setColors] = useState([])
  const handleImageChange = e => {
    console.log("myzft swraaa", e.target.files[0])
    setSelectedFile(e.target.files[0])
    // setSelectedFile("image")
  }
  const handleSizeImageChange = e => {
    console.log("swereet elsize", e.target.files[0])
    setSelectedSizeImage(e.target.files[0])

    // setSelectedSizeImage("sizeImage")
  }

  const handleAddColor = () => {
    console.log("addddddddddd")
    setX([
      ...colorArr,
      {
        color_code: "",
        sizes: [{ size: "", quantity: 0 }],
      },
    ])
  }

  getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo
      let baseURL = ""
      // Make new FileReader
      let reader = new FileReader()

      // Convert the file to base64 text
      reader.readAsDataURL(file)

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader)
        baseURL = reader.result
        console.log(baseURL)
        resolve(baseURL)
      }
      console.log(fileInfo)
    })
  }

  const colorChange = e => {}
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      category_id: 1,
      en_name: "",
      ar_name: "",
      en_description: "",
      ar_description: "",
      price: "",
      size_guide: null,
      discount: 0,
      colors: colorArr,
    },
    validationSchema: Yup.object({
      en_name: Yup.string().required("Please Enter english name"),
      ar_name: Yup.string().required("Please Enter arabic name"),
    }),
    onSubmit: values => {
      console.log(values)
      let formData = new FormData()
      formData.append("size_guide", selectedSizeImage)
      formData.append("en_name", values.en_name)
      formData.append("category_id", values.category_id)
      formData.append("ar_name", values.ar_name)
      formData.append("en_description", values.en_description)
      formData.append("ar_description", values.ar_description)
      formData.append("price", values.price)
      formData.append("colors", JSON.stringify(values.colors))
      // formData.append("image", selectedimageFile)
      // values.colors.forEach(item => {
      //   formData.append("colors[]", JSON.stringify(item))
      // })
      // console.log("colors", formData.getAll("colors[]"))
      // console.log("image", formData.get("size_guide"))

      // console.log("mydata", formData.has("colors"), formData.get("colors"))
      // formData.append("discount", values.discount)
      // // formData.append("image", selectedFile)
      // formData.append("size_guide", selectedSizeImage)
      // values.colors[0].image = selectedimageFile
      // values.size_guide = selectedSizeImage
      // values.size_guide = selectedSizeImage
      // console.log(values)
      // console.log("colors", formData.get("colors"))
      // console.log("a7aaaaaaaaaaaaa", values.size_guide)
      // console.log("finallllll", values)
      // console.log(values)

      console.log(formData)
      console.log("valuee send", values)
      console.log("color_array", colorArr)
      axios
        .post(`${apiLink}/dashboard/product/store`, formData)
        .then(response => {
          // setData(response.data)
          console.log(response)
          setSuccess(true)
        })

      // toggle()
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add Product | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Add Product" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Basic Information</CardTitle>
                  <CardSubtitle className="mb-4">
                    Fill all information below
                  </CardSubtitle>

                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row form>
                      <Col xs={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            product name (english)
                          </Label>
                          <Input
                            name="en_name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.en_name || ""}
                            invalid={
                              validation.touched.en_name &&
                              validation.errors.en_name
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            product name (Arabic)
                          </Label>
                          <Input
                            name="ar_name"
                            label="Designation"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.ar_name || ""}
                            invalid={
                              validation.touched.ar_name &&
                              validation.errors.ar_name
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Col>

                      <Col xs={12}>
                        <div className="mb-3">
                          <Label className="form-label">Price</Label>
                          <Input
                            name="price"
                            label="Designation"
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.price || ""}
                            invalid={
                              validation.touched.price &&
                              validation.errors.price
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Col>
                      <Col xs={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            English description
                          </Label>
                          <textarea
                            name="en_description"
                            label="Designation"
                            type="number"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.en_description || ""}
                          />
                        </div>
                      </Col>

                      <Col xs={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Arabic description
                          </Label>
                          <textarea
                            name="ar_description"
                            label="Designation"
                            type="number"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.ar_description || ""}
                          />
                        </div>
                      </Col>

                      {validation.values.colors.map((item, i, array) => {
                        // console.log("key", array[i].Object.keys)
                        return (
                          <React.Fragment key={item.id}>
                            <Col xs={12}>
                              <div className="mb-3">
                                <Label className="form-label">color_code</Label>
                                <Input
                                  name={`colors.${i}.color_code`}
                                  type="color"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    colorArr
                                      ? validation.values.colors[i]
                                          .color_code || ""
                                      : ""
                                  }
                                  // invalid={
                                  //   colorArr
                                  //     ? validation.touched.colors[0].color_code &&
                                  //       validation.errors.colors[0].color_code
                                  //       ? true
                                  //       : false
                                  //     : ""
                                  // }
                                />
                              </div>
                            </Col>
                            <Col xs={12}>
                              <div className="mb-3">
                                <Label className="form-label">Size</Label>
                                <Input
                                  name={`colors.${i}.sizes.${0}.size`}
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    colorArr
                                      ? validation.values.colors[i].sizes[0]
                                          .size || ""
                                      : ""
                                  }
                                  // invalid={
                                  //   colorArr
                                  //     ? validation.touched.colors[0].sizes[0].size &&
                                  //       validation.errors.colors[0].sizes[0].size
                                  //       ? true
                                  //       : false
                                  //     : ""
                                  // }
                                />
                              </div>
                            </Col>
                            <Col xs={12}>
                              <div className="mb-3">
                                <Label className="form-label">Quantity</Label>
                                <Input
                                  name={`colors.${i}.sizes.${0}.quantity`}
                                  type="number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    colorArr
                                      ? validation.values.colors[i].sizes[0]
                                          .quantity || ""
                                      : ""
                                  }
                                  // invalid={
                                  //   colorArr
                                  //     ? validation.touched.colors[0].quantity &&
                                  //       validation.errors.colors[0].quantity
                                  //       ? true
                                  //       : false
                                  //     : ""
                                  // }
                                />
                              </div>
                            </Col>
                          </React.Fragment>
                        )
                        return {}
                      })}

                      {/* <Col xs={12}>
                        <div className="mb-3">
                          <Label className="form-label">color_code</Label>
                          <Input
                            name="colors[0].color_code"
                            type="color"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              colorArr
                                ? validation.values.colors[0].color_code || ""
                                : ""
                            }
                            // invalid={
                            //   colorArr
                            //     ? validation.touched.colors[0].color_code &&
                            //       validation.errors.colors[0].color_code
                            //       ? true
                            //       : false
                            //     : ""
                            // }
                          />
                        </div>
                      </Col> */}

                      {/* <Col xs={12}>
                        <div className="mb-3">
                          <Label className="form-label">Size</Label>
                          <Input
                            name="colors[0].sizes[0].size"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              colorArr
                                ? validation.values.colors[0].sizes[0].size ||
                                  ""
                                : ""
                            }
                            // invalid={
                            //   colorArr
                            //     ? validation.touched.colors[0].sizes[0].size &&
                            //       validation.errors.colors[0].sizes[0].size
                            //       ? true
                            //       : false
                            //     : ""
                            // }
                          />
                        </div>
                      </Col> */}

                      {/* <Col xs={12}>
                        <div className="mb-3">
                          <Label className="form-label">Quantity</Label>
                          <Input
                            name="colors[0].sizes[0].quantity"
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={
                              colorArr
                                ? validation.values.colors[0].sizes[0]
                                    .quantity || ""
                                : ""
                            }
                            // invalid={
                            //   colorArr
                            //     ? validation.touched.colors[0].quantity &&
                            //       validation.errors.colors[0].quantity
                            //       ? true
                            //       : false
                            //     : ""
                            // }
                          />
                        </div>
                      </Col> */}
                      <button type="button" onClick={handleAddColor}>
                        Add Color
                      </button>
                      <Col xs={12}>
                        <div className="mb-3">
                          <Label className="form-label">Size Guide image</Label>
                          <Input
                            name="icon"
                            label="Designation"
                            type="file"
                            onChange={handleSizeImageChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.size_guide || ""}
                            invalid={
                              validation.touched.size_guide &&
                              validation.errors.size_guide
                                ? true
                                : false
                            }
                          />
                        </div>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-user"
                            >
                              Save
                            </button>
                          </div>
                        </Col>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col>
                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-success save-user"
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row> */}
                  </Form>
                </CardBody>
              </Card>

              {/* <Card>
                <CardBody>
                  <CardTitle className="mb-3">Product Images</CardTitle>
                  <Form>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="dz-message needsclick">
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                  </Form>
                </CardBody>
              </Card> */}

              <Card>
                <CardBody>
                  <CardTitle>Meta Data</CardTitle>
                  <CardSubtitle className="mb-3">
                    Fill all information below
                  </CardSubtitle>

                  {/* <Form>
                    <Row>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="metatitle">Meta title</Label>
                          <Input
                            id="metatitle"
                            name="productname"
                            type="text"
                            className="form-control"
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="metakeywords">Meta Keywords</Label>
                          <Input
                            id="metakeywords"
                            name="manufacturername"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </Col>

                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="metadescription">
                            Meta Description
                          </Label>
                          <textarea
                            className="form-control"
                            id="metadescription"
                            rows="5"
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="">
                        Save Changes
                      </Button>
                      <Button type="submit" color="secondary" className="">
                        Cancel
                      </Button>
                    </div>
                  </Form> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceTestProduct
