import {
  GET_CART_DATA_FAIL,
  GET_CART_DATA_SUCCESS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAIL,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_SHOPS_FAIL,
  GET_SHOPS_SUCCESS,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_DETAIL_FAIL,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAIL,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  GET_PRODUCT_COMMENTS_SUCCESS,
  GET_PRODUCT_COMMENTS_FAIL,
  ON_LIKE_COMMENT_SUCCESS,
  ON_LIKE_REPLY_SUCCESS,
  ON_ADD_REPLY_SUCCESS,
  ON_ADD_COMMENT_SUCCESS,
  DELETE_SHOPS,
  DELETE_SHOPS_SUCCESS,
  GET_PROMOS_SUCCESS,
  DELETE_PROMO,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  DELETE_CATEGORY,
  // GET_SLIDER,
  GET_SLIDER_SUCCESS,
  DELETE_SLIDER,
  GET_TYPES_SUCCESS,
  DELETE_TYPE,
  DELETE_CITY,
  GET_DISCOUNT_TYPES_SUCCESS,
  DELETE_DISCOUNT_TYPES,
} from "./actionTypes"

const INIT_STATE = {
  products: [],
  product: {},
  orders: [],
  cartData: {},
  customers: [],
  shops: [],
  error: {},
  productComments: [],
  successMessage: null,
  promos: [],
  cities: [],
  categories: [],
  slider: [],
  types: [],
  discountTypes: [],
}

const Ecommerce = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
      }

    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        product: action.payload,
      }

    case GET_PRODUCT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload,
      }

    case GET_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_ORDER_SUCCESS:
      return {
        ...state,
        orders: [...state.orders, action.payload],
      }

    case ADD_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        orders: state.orders.map(order =>
          order.id === action.payload.id.toString()
            ? { order, ...action.payload }
            : order
        ),
      }

    case UPDATE_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        orders: state.orders.filter(
          order => order.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CART_DATA_SUCCESS:
      return {
        ...state,
        cartData: action.payload,
      }

    case GET_CART_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
      }

    case GET_CUSTOMERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: [...state.customers, action.payload],
      }

    case ADD_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: state.customers.map(customer =>
          customer.id.toString() === action.payload.id.toString()
            ? { customer, ...action.payload }
            : customer
        ),
      }

    case UPDATE_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: state.customers.filter(
          customer => customer.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: action.payload,
      }

    case GET_CITIES_FAIL:
      return {
        ...state,
        cities: action.payload,
      }

    case DELETE_CITY:
      console.log("deleteId", action.payload)
      return {
        ...state,
        cities: state.cities.filter(
          city => city.id.toString() !== action.payload.toString()
        ),
      }

    case GET_DISCOUNT_TYPES_SUCCESS:
      return {
        ...state,
        discountTypes: action.payload,
      }

    case GET_TYPES_SUCCESS:
      return {
        ...state,
        types: action.payload,
      }
    case DELETE_TYPE:
      return {
        ...state,
        types: state.types.filter(
          type => type.id.toString() !== action.payload.toString()
        ),
      }

    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
      }

    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        categories: action.payload,
      }

    case DELETE_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter(
          category => category.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_DISCOUNT_TYPES:
      return {
        ...state,
        discountTypes: state.discountTypes.filter(
          discount => discount.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: state.categories.filter(
          category => category.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SLIDER_SUCCESS:
      return {
        ...state,
        slider: action.payload,
      }

    case DELETE_SLIDER:
      return {
        ...state,
        slider: state.slider.filter(
          slider => slider.id.toString() !== action.payload.toString()
        ),
      }
    case GET_SHOPS_SUCCESS:
      return {
        ...state,
        shops: action.payload,
      }

    case GET_PROMOS_SUCCESS:
      console.log("this is payload", action.payload)
      return {
        ...state,
        promos: action.payload,
      }
    case DELETE_PROMO:
      return {
        ...state,
        promos: state.promos.filter(
          promo => promo.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_SHOPS:
      return {
        ...state,
        shops: state.shops.filter(
          shop => shop.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_SHOPS_SUCCESS:
      return {
        ...state,
        successMessage: action.payload,
      }

    // case DELETE_SHOPS_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }

    case GET_SHOPS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PRODUCT_COMMENTS_SUCCESS:
    case ON_LIKE_COMMENT_SUCCESS:
    case ON_LIKE_REPLY_SUCCESS:
    case ON_ADD_REPLY_SUCCESS:
    case ON_ADD_COMMENT_SUCCESS:
      return {
        ...state,
        productComments: action.payload,
      }

    case GET_PRODUCT_COMMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Ecommerce
