import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import * as Yup from "yup"
import { useFormik } from "formik"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

import images from "assets/images"
import { getShops as onGetShops } from "store/e-commerce/actions"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  // deleteUser as onDeleteUser,
} from "store/contacts/actions"
import { getPromos } from "store/actions"
import { getTypes, deleteType } from "store/actions"
import { isEmpty, size, map } from "lodash"
import { deleteShopSuccess } from "store/actions"
//redux
import { useSelector, useDispatch } from "react-redux"
// actions
// import { getDemoData } from "./../../../store/demo/actions"
import { deletePromo } from "store/e-commerce/actions"
import axios from "axios"

const apiLink = "https://beneshty.oncreation.net/api"

const ContactsList = props => {
  const [data, setData] = useState(null)
  const dispatch = useDispatch()
  const [contact, setContact] = useState()
  const [edit, setEdit] = useState(null)
  const [add, setAdd] = useState(null)
  const [editId, setId] = useState()
  const [selectedFile, setSelectedFile] = useState()

  const handleChange = e => {
    setSelectedFile(e.target.files[0])
  }
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      en_name: (contact && contact.en_name) || "",
      ar_name: (contact && contact.ar_name) || "",
    },
    validationSchema: Yup.object({
      en_name: Yup.string().required("Please Enter english name"),
      ar_name: Yup.string().required("Please Enter arabic name"),
    }),
    onSubmit: values => {
      axios.post(`${apiLink}/dashboard/type/store`, values).then(response => {
        setData(response.data)
      })

      toggle()
    },
  })

  // const { users } = useSelector(state => ({
  //   users: state.contacts.users,
  // }))

  const { successMessage } = useSelector(state => ({
    successMessage: state.ecommerce.successMessage,
  }))

  //   const { slider } = useSelector(state => ({
  //     slider: state.ecommerce.slider,
  //   }))
  const { types } = useSelector(state => ({
    types: state.ecommerce.types,
  }))

  useEffect(() => {
    if (successMessage == null) {
      return
    } else {
      alert(successMessage)
    }
  }, [successMessage])
  const [userList, setUserList] = useState([])

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const { SearchBar } = Search
  const sizePerPage = 10
  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: types.length, // replace later with size(users),
    custom: true,
  }
  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "asc", // desc or asc
    },
  ]

  const selectRow = {
    mode: "checkbox",
  }

  const contactListColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,

      // eslint-disable-next-line react/display-name
      formatter: (cellContent, type) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {type.id}
            </Link>
          </h5>
          {/* <p className="text-muted mb-0">{user.designation}</p> */}
        </>
      ),
    },
    {
      text: "English Name",
      dataField: "en_name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, type) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {type.en_name}
            </Link>
          </h5>
        </>
      ),
    },

    {
      text: "Arabic Name",
      dataField: "ar_name",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, type) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {type.ar_name}
            </Link>
          </h5>
        </>
      ),
    },

    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, slider) => (
        <div className="d-flex gap-3">
          {/* <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => handleUserClick(category)}
            ></i>
          </Link> */}
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => onClickDelete(slider, slider.id)}
            ></i>
          </Link>
        </div>
      ),
    },
  ]
  useEffect(() => {
    dispatch(getTypes())
  }, [dispatch, data])

  useEffect(() => {
    if (types && !types.length) {
      dispatch(getTypes())
      setIsEdit(false)
    }
  }, [dispatch, types, data])

  useEffect(() => {
    setContact(types)
    setIsEdit(false)
  }, [types])

  useEffect(() => {
    if (!isEmpty(types) && !!isEdit) {
      setContact(types)
      setIsEdit(false)
    }
  }, [types, data])

  const toggle = () => {
    setModal(!modal)
  }

  //   const handleUserClick = arg => {
  //     const category = arg
  //     setAdd(false)
  //     setEdit(true)
  //     setId(category.id)
  //     setContact({
  //       id: category.id,
  //       en_name: category.en_name,
  //       ar_name: category.ar_name,
  //       icon: category.icon,
  //       type_id: category.type_id,
  //     })
  //     setIsEdit(true)

  //     toggle()
  //   }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = (slide, id) => {
    setContact(slide)
    setDeleteModal(true)
  }

  const handleDeleteShop = () => {
    dispatch(deleteType(contact.id))
    onPaginationPageChange(1)
    setDeleteModal(false)
  }

  const handleUserClicks = () => {
    setEdit(false)
    setAdd(true)
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteShop}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>User List | Skote - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="User List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField={keyField}
                    columns={contactListColumns}
                    data={types}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField={keyField}
                          data={types}
                          columns={contactListColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                      color="primary"
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={handleUserClicks}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Create New Type
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={keyField}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      // selectRow={selectRow}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={node}
                                    />

                                    <Modal isOpen={modal} toggle={toggle}>
                                      <ModalHeader toggle={toggle} tag="h4">
                                        {!!isEdit ? "Edit type" : "Add type"}
                                      </ModalHeader>
                                      <ModalBody>
                                        <Form
                                          onSubmit={e => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                          }}
                                        >
                                          <Row form>
                                            <Col xs={12}>
                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  English name
                                                </Label>
                                                <Input
                                                  name="en_name"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.en_name ||
                                                    ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .en_name &&
                                                    validation.errors.en_name
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.en_name &&
                                                validation.errors.en_name ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.en_name}
                                                  </FormFeedback>
                                                ) : null}
                                              </div>

                                              <div className="mb-3">
                                                <Label className="form-label">
                                                  Arabic name
                                                </Label>
                                                <Input
                                                  name="ar_name"
                                                  label="Designation"
                                                  type="text"
                                                  onChange={
                                                    validation.handleChange
                                                  }
                                                  onBlur={validation.handleBlur}
                                                  value={
                                                    validation.values.ar_name ||
                                                    ""
                                                  }
                                                  invalid={
                                                    validation.touched
                                                      .ar_name &&
                                                    validation.errors.ar_name
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                {validation.touched.ar_name &&
                                                validation.errors.ar_name ? (
                                                  <FormFeedback type="invalid">
                                                    {validation.errors.ar_name}
                                                  </FormFeedback>
                                                ) : null}
                                              </div>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <div className="text-end">
                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-user"
                                                >
                                                  Save
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Form>
                                      </ModalBody>
                                    </Modal>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ContactsList)
